import React from 'react';
import { graphql } from 'gatsby';
import ResourceCard from './resource-card';
import './index.scss';

export default function RelatedResources ({ relatedResourcesHeading, relatedResources }) {
  if (!relatedResources?.length) return null;

  const cards = relatedResources.map((resource, i) => {
    return (
      <ResourceCard resource={resource} key={i} />
    );
  });

  return (
    <section className="related-resources">
      <div className="container related-resources__inner">
        <h4>{relatedResourcesHeading}</h4>
        <div
          className="resource-cards"
          style={{ gridTemplateColumns: `repeat(${relatedResources.length}, auto)` }}
        >
          {cards}
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment relatedResourcesFields on ContentfulResource {
  ...resourceCardFields
}
`;

import React, { useContext } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { NavigationContext } from '../navigation';
import './index.scss';

export default function RelatedSolutions ({ relatedSolutionsHeading, relatedSolutions }) {
  const navigationMap = useContext(NavigationContext);

  const data = useStaticQuery(graphql`
  query RelatedSolutionsPrimaryCategoriesQuery {
    contentfulGlobalOptions {
      primarySolutionCategories {
        id
        title { title }

        contentfulchildren {
          __typename
          ... on ContentfulSolutionCategory {
            id
            title { title }
            contentfulchildren {
              __typename
              ... on ContentfulSolution {
                id
              }
            }
          }
          ... on ContentfulSolution {
            id
          }
        }
      }
    }
  }
  `);

  if (!relatedSolutions?.length) return null;

  const { primarySolutionCategories } = data.contentfulGlobalOptions;

  const columns = primarySolutionCategories.map((solutionCategory, i) => {
    const solutions = relatedSolutions.filter((solution) => {
      // show the solution under it's primary category
      if (solution.solutionCategory?.id === solutionCategory.id) return true;

      // also show the solution under any other primary cateogry it's listed under
      return solutionCategory.contentfulchildren.find((c) => {
        if (c.id === solution.id) return true;

        // or primary category for third-level category it's listed under
        return c.contentfulchildren?.find(c1 => c1.id === solution.id);
      });
    }).map((solution, j) => {
      return (
        <li key={j}>
          <Link to={navigationMap.get(solution.id)?.path}>{solution.title?.title}</Link>
        </li>
      );
    });

    return (
      <div className="solution-category" key={i}>
        <h6>{solutionCategory.title?.title}</h6>
        <ul>{solutions}</ul>
      </div>
    );
  });

  return (
    <section className="related-solutions">
      <div className="container related-solutions__inner">
        <h4>{relatedSolutionsHeading}</h4>
        <div className="related-solutions-lists">
          {columns}
        </div>
      </div>
    </section>
  );
}

export const query = graphql`
fragment relatedSolutionsFields on ContentfulSolution {
  id
  title { title }

  solutionCategory {
    id,
    title { title }
  }
}
`;

import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout, renderModules } from '../../components';
import { HeroBackgroundImage } from '../../components/images';
import RichText, { renderFlatRichText } from '../../components/rich-text';
import RelatedResources from '../../components/related-resources';
import RelatedSolutions from '../../components/related-solutions';
import './index.scss';

export default function SolutionCategory ({ path, data }) {
  const {
    title, heroHeading, heroText, modules, metaDescription, heroBackgroundImage,
    relatedResourcesHeading, relatedResources, relatedSolutionsHeading, relatedSolutions, ogImage
  } = data.contentfulSolution;

  return (
    <Layout currentPath={path}>
      <Helmet>
        <title>{title?.title}</title>
        <meta name="description" content={metaDescription?.metaDescription} />
        <meta name="og:title" content={title?.title} />
        <meta name="og:description" content={metaDescription?.metaDescription} />
        <meta name="og:image" content={ogImage?.fixed?.src} />
      </Helmet>

      <section className="hero">
        <HeroBackgroundImage heroBackgroundImage={heroBackgroundImage} />
        <div className="container hero__inner">
          <h3>{renderFlatRichText({ node: heroHeading })}</h3>
          <div className="hero-text"><RichText node={heroText} /></div>
        </div>
      </section>

      {renderModules(modules)}

      <RelatedResources
        relatedResourcesHeading={relatedResourcesHeading}
        relatedResources={relatedResources}
      />
      <RelatedSolutions
        relatedSolutionsHeading={relatedSolutionsHeading}
        relatedSolutions={relatedSolutions}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
query SolutionById($id: String!) {
  contentfulSolution(id: {eq: $id}) {
    id, shortTitle, relatedResourcesHeading, slug, relatedSolutionsHeading
    title { title }
    metaDescription { metaDescription }
    heroHeading { raw }
    heroText { raw }

    ogImage: heroBackgroundImage {
      ...pageOgImageFields
    }

    heroBackgroundImage {
      ...heroBackgroundImageFields
    }

    relatedResources {
      ...relatedResourcesFields
    }

    relatedSolutions {
      ...relatedSolutionsFields
    }

    modules {
      __typename
      ... on ContentfulArticleContentModule {
        ...articleContentModuleFields
      }
      ... on ContentfulBeyondZeroHarmModule {
        ...beyondZeroHarmModuleFields
      }
      ... on ContentfulContactModule {
        ...contactModuleFields
      }
      ... on ContentfulCtaArrowsModule {
        ...ctaArrowsModuleFields
      }
      ... on ContentfulFeatureModule {
        ...featureModuleFields
      }
      ... on ContentfulFeaturedResourcesModule {
        ...featuredResourcesModuleFields
      }
      ... on ContentfulFeaturedSolutionsModule {
        ...featuredSolutionsModuleFields
      }
      ... on ContentfulImageVideoModule {
        ...imageVideoModuleFields
      }
      ... on ContentfulImagesWithArrowModule {
        ...imagesWithArrowModuleFields
      }
      ... on ContentfulLeadershipModule {
        ...leadershipModuleFields
      }
      ... on ContentfulPrimaryContentModule {
        ...primaryContentModuleFields
      }
      ... on ContentfulQuoteModule {
        ...quoteModuleFields
      }
      ... on ContentfulSideBlockModule {
        ...sideBlockModuleFields
      }
      ... on ContentfulTwoColumnModule {
        ...twoColumnModuleFields
      }
    }
  }
}
`;

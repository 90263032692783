import React, { useContext } from 'react';
import { graphql, Link } from 'gatsby';
import { NavigationContext } from '../navigation';
import cornerTriangleImg from '../../images/corner-triangle.svg';
import iconPlus from '../../images/icon-plus.svg';
import iconVideo from '../../images/icon-video.svg';
import iconDownload from '../../images/icon-download.svg';
import './resource-card.scss';

const RESOURCE_TYPE_DEFAULT = 'Page';
const RESOURCE_TYPE_ICONS = {
  'Page': iconPlus,
  'Download': iconDownload,
  'Video': iconVideo
};

export default function ResourceCard ({ resource }) {
  const navigationMap = useContext(NavigationContext);

  if (resource.__typename === 'ContentfulEvent') {
    resource.category = 'Event';
    resource.type = 'Page';
  }

  return (
    <Link className="card" to={navigationMap.get(resource.id).path}>
      <div
        className="card-background-image"
        style={{ background: `center / cover no-repeat url(${resource.cardBackgroundImage?.fixed?.src})` }}
      ></div>
      <div className="card-content">
        <h6 className="card-category">{resource.category}</h6>
        <h5 className="card-heading">{resource.shortTitle ?? resource.title?.title}</h5>
        <p className="card-p">{resource.metaDescription?.metaDescription}</p>
      </div>
      <img className="corner-triangle" src={cornerTriangleImg} alt="" role="presentation" />
      <img
        className="corner-icon"
        src={RESOURCE_TYPE_ICONS[resource.type ?? RESOURCE_TYPE_DEFAULT]}
        alt=""
        role="presentation"
      />
    </Link>
  );
}

export const query = graphql`
fragment resourceCardFields on ContentfulResource {
  id, category, type
  title { title }
  shortTitle
  metaDescription { metaDescription }

  cardBackgroundImage {
    fixed(width: 400, height: 200, quality: 90) {
      src, srcSet
    }
  }
}

fragment resourceEventCardFields on ContentfulEvent {
  __typename, id
  title { title }
  shortTitle
  metaDescription { metaDescription }

  cardBackgroundImage {
    fixed(width: 400, height: 200, quality: 90, toFormat: JPG) {
      src, srcSet
    }
  }
}
`;
